.marquee {
    animation: marquee 8s linear infinite;
}

@keyframes marquee {
    from {
        transform: translateX(calc(-50% - 2rem));
    }

    to {
        transform: translateX(0%);
    }
}